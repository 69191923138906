import React, { useState } from 'react';
import OpenAI from 'openai';

// Initialize the OpenAI client
const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});

function App() {
    const [isStreaming, setIsStreaming] = useState(false);
    const [isLoading, setIsLoading] = useState(false);  // Loading state
    const [output, setOutput] = useState('');
    const [file, setFile] = useState(null);
    const [fileContent, setFileContent] = useState(null);
    const [aiFileContent, setAIFileContent] = useState(null);

    const [assistant] = useState('asst_tJhoGpcTxKamAd0pdVqxH5W1');

    // Handles selecting a file
    const handleChooseFile = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const chosenFile = e.target.files[0];
        setFile(chosenFile);
        let text = await chosenFile.text();
        setFileContent(text);
    };

    // Uploads the file and sends the request to interact with the AI
    const handleUpload = async () => {
        setIsLoading(true);  // Start loading when upload begins
        const fileObj = await openai.files.create({
            file: file,
            purpose: 'assistants',
        });
        interactWithAssistant(fileObj.id);  // Pass file ID to the assistant interaction function
        getOriginalFileContent(file);
    };

    const getOriginalFileContent = async () => {
        if (file) {
            let fileContent = await file.text();
            setFileContent(fileContent);  // Display file content in the UI
        }
    };

    // Handles interacting with the assistant using file content
    const interactWithAssistant = async (fileId) => {
        // Create a thread
        const thread = await openai.beta.threads.create({
            tool_resources: {
                file_search: {
                    vector_store_ids: ['vs_zFHeKgsNGfmuPYcwYKTdg3ZG'],
                },
            },
        });

        console.log('Thread created with id:', thread.id);

        // Send the message with the prompt and attached file
        await openai.beta.threads.messages.create(thread.id, {
            role: 'user',
            content: `
<example>
<instructions>  
Attached are the user's notes. Please enhance them by referencing the uploaded file and adding relevant missing information.  
</instructions>

<important>  
1. The user believes they are interacting with a textbook. Ensure all content is factual and sourced from the provided file. Do NOT fabricate information or use the example content as a reference for the subject matter. The example is for format purposes only.  
2. Format the notes as an outline using HTML. Do not attach or provide a separate file.  
</important>

<always-do>  
1. Enrich the content with additional information from the uploaded file / vector store. Use the vector store as your primary reference.  
2. Clearly highlight the sections you’ve added by using a custom blue background color for those areas.  
3. Respond with the originals original notes and fix spelling errors.
</always-do>

<never-do>  
1. Never reference or mention any code, including formats like markdown.  
2. Never include back ticks in the response.  
3. Do not acknowledge the user’s request or reference the process of rewriting.  
</never-do>

<example-response>  
  <h1>Chapter 1...</h1>

  <h2>Section 1.1...</h2>

  <ul>
    <li><strong>{vector store content}:</strong>
      <ul>
        <li><strong>{vector store content}</strong>
          <ul>
            {vector store content}
          </ul>
        </li>
    </li>
  </ul>

  <h2>Section....</h2>
 {continue}
  <h2>Key Terms</h2>

  <ul>
{key terms from vector store}
  </ul>
</example-response>  
<user-input>
    {fileContent}
</user-input>
<remember>  
2. Clearly highlight the sections you’ve added by using hex color #e6f9ff for that content.
</remember>

            `,
            attachments: [
                {
                    file_id: fileId,
                    tools: [
                        {
                            type: 'file_search',  // Use file_search to ensure file content is used
                        },
                    ],
                },
            ],
        });

        // Start streaming the AI response
        console.log('Starting stream...');
        openai.beta.threads.runs
            .stream(thread.id, {
                assistant_id: assistant,
            })
            .on('textDelta', (textDelta, snapshot) => {
                if (typeof textDelta.value === 'undefined' && textDelta.annotations) {
                    if (textDelta.annotations[0].file_path.file_id) {
                        getNewFile(textDelta.annotations[0].file_path.file_id);
                    }
                } else if (textDelta.value) {
                    setIsLoading(false);  // Stop loading when the AI finishes
                    setOutput((prevOutput) => `${prevOutput}${textDelta.value}`);
                }
            })
            .on('messageDone', async (event) => {

                setIsStreaming(false);
            })
            .on('error', (error) => {
                console.log('Error:', error);
                setIsLoading(false);  // Stop loading on error
                setIsStreaming(false);
            });
    };

    // Retrieve any newly generated file by the assistant (if applicable)
    const getNewFile = async (fileId) => {
        const newFile = await openai.files.retrieve(fileId);
        const newFileName = newFile.filename;
        const newFileContent = await openai.files.content(fileId);
        const newFileText = await newFileContent.text();
        if (newFileText) {
            setAIFileContent(newFileText);
        }

        const blob = new Blob([newFileText], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = newFileName;
        document.body.appendChild(a);
        a.click();

        // Clean up
        a.remove();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className="App">
            <input type="file" onChange={handleChooseFile} />
            <button onClick={handleUpload}>Upload and Process</button>
            <div style={{ maxHeight: '300px', overflow: 'auto', margin: '24px' }}>
            </div>
            <div style={{ display: 'flex', width:'100%' }}>
                <div style={{flex: '1', margin: '24px', lineHeight: '1.5'}}>
                    <h2>Users Notes:</h2>
                    {fileContent ? (
                        <div style={{whiteSpace: 'pre', display: 'flex', background:'white', padding:'40px'}}>{fileContent}</div>
                    ) : (
                        'Original notes show up here'
                    )}
                </div>
                <div style={{flex: '1', margin: '24px', lineHeight: '1.5'}}>
                    <h2>AI Revisions:</h2>
                    {isLoading ? (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div className="spinner"></div>  {/* Spinner component */}
                        </div>
                    ) : output ? (
                        <div style={{display: 'flex', flexDirection: "column", background:'white', padding:'40px'}}
                             dangerouslySetInnerHTML={{__html: output}}></div>
                    ) : (
                        'AI notes show up here'
                    )}
                </div>
            </div>
        </div>
    );
}

export default App;
